import styled from "styled-components"

const media = { 
    bigDesktop: "@media(min-width: 1072px)",
    desktop: "@media(min-width: 850px)",
    tablet: "@media(min-width: 200px)"
 };

export const HeroTextWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;

    ${media.tablet} {
        h1 {
            font-size: 20pt;
        }
    
        h2 {
            font-size: 18pt;
            font-weight: 400;
        }
    }

    ${media.desktop} {
        h1 {
            font-size: 24pt;
        }
    
        h2 {
            font-size: 20pt;
            font-weight: 400;
        }
    }

    ${media.bigDesktop} {
        h1 {
            font-size: 36pt;
        }
    
        h2 {
            font-size: 28pt;
            font-weight: 400;
        }
    }
`