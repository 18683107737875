import React from 'react'
import Hero from './Components/Hero'
import About from './Components/About'
import Contact from './Components/Contact'
import './styles.css'

function App() {
    return (
        <div>
            <Hero/>
            <About />
            <Contact />
        </div>
    )
}

export default App