import React from 'react'
import { HeroTextWrap } from '../../Elements/HeroTextWrap'
import { Helmet } from 'react-helmet-async'

function HeroText() {
    return(
        <HeroTextWrap>
            <Helmet>
                <title>Dundas Grenville Christian Home Educator’s Association</title>
                <meta name="description" content="Information about the Dundas Grenville Christian Home Educator’s Association" />
                <meta name="keywords" content="christian, dgchea, north dundas, south dundas, homeschool, home school, homeschooling, grenville, home education, home educator" />
                <link rel="canonical" href='/' />
            </Helmet>
            <h1>Dundas Grenville Christian Home Educator’s Association</h1>
            <h2><em>We aspire to be a safe place for homeschooling families where we share values and teach our kids in the ways of Jesus.</em></h2>
        </HeroTextWrap>
    )
}

export default HeroText