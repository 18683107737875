import styled from "styled-components"

export const HeadingWrap = styled.div`
    h3 {
        font-size: 26px;
    }

    hr {
        width: 100px;
    }
`