import React from 'react';
import ReactDOM from 'react-dom/client'
import App from './App'
import { HelmetProvider } from 'react-helmet-async'

const el = document.getElementById('root')

const root = ReactDOM.createRoot(el)

root.render(
    <HelmetProvider>
        <App />
    </HelmetProvider>
)