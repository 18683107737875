import styled from "styled-components"

const media = { 
    desktop: "@media(min-width: 850px)",
    phone: "@media(min-width: 200px)"
 };

export const HeroImageWrap = styled.div`
    
    ${media.phone} {
        padding: 20px;
        img {
            width: 300px;
        }
    }

    ${media.desktop} {
        padding-left: 50px;
    
        img {
            width: 550px;
        }
    }
`