import styled from "styled-components"

const media = { 
    desktop: "@media(min-width: 850px)",
    tablet: "@media(min-width: 200px)"
 };

export const HeroWrap = styled.div`
    display: flex;

    ${media.tablet} {
        padding: 50px;
        flex-direction: column;
        align-items: center;
    }

    ${media.desktop} {
        padding: 20px;
        flex-direction: row;
    }
`




