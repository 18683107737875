import styled from "styled-components";

export const TextWrap = styled.div`
    font-size: 18px;

    p {
        margin-top: 10px;
    }

    a {
        color: #CA3C25;
        text-decoration: none;
    }

    a:hover {
        color: #792316;
        text-decoration: underline;
    }
`